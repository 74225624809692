import React from "react";
import alpha from "../assets/img/icons/horace-cube.png";
import redx from "../assets/img/icons/cnp.png";
import reachlogo from "../assets/img/icons/reachlogo.png";
import Navi from "./Navi";
const Solution = () => {
  return (
    <>
      <Navi navType="dark" />
      <section id="solutions" className="features-2-section">
        <div className="container">
          <div className="section-header text-center">
            <h2 className="wow fadeInUp" data-wow-delay=".2s">
              Our Solutions
            </h2>
            <p className="wow fadeInUp" data-wow-delay=".4s">
              Education :: ERP :: CRM :: SME
            </p>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="icon-feature wow fadeInUp" data-wow-delay=".2s">
                <img src={alpha} alt="horace learning" />

                <div className="content">
                  <h4>Horace Learning</h4>
                  <p>
                    360 degree school information management system for all
                    schools.
                  </p>
                  <ul>
                    <li>Tuition Collections</li>
                    <li>Online Learning</li>
                    <li>Reporting</li>
                    <li>Messaging</li>
                    <li>Administration</li>
                  </ul>
                  <a href="https://horacelearning.com/">
                    {" "}
                    <button className="btn btn-danger">Join Horace</button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="icon-feature wow fadeInUp" data-wow-delay=".4s">
                <img src={redx} alt="cars" />

                <div className="content">
                  <h4>Cars & Parts</h4>
                  <p>Buy and sell affordable cars online.</p>
                  <ul>
                    <li>Find sellers near you</li>
                    <li>Chat with seller </li>
                    <li>Test the car</li>
                    <li>Pay secure</li>
                    <li>Browse inventory</li>
                  </ul>
                  <a
                    href="https://play.google.com/store/apps/details?id=net.myeverlasting.cnp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="btn btn-primary">
                      Download carsNparts
                    </button>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="icon-feature wow fadeInUp" data-wow-delay=".2s">
                <img src={reachlogo} alt="reach" />
                <div className="content">
                  <h4>Reach AI</h4>
                  <p>AI solution that support small business.</p>
                  <ul>
                    <li>Generate corporate website</li>
                    <li>Get corporate email </li>
                    <li>Perform SEO audit</li>
                    <li>Manage invoice and receipt</li>
                    <li>Manage product & staff</li>
                  </ul>
                  <a
                    href="https://reachai.online"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="btn btn-primary">Join ReachAI</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Solution;
