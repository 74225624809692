import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import wow from "wow.js";
import { esslCourse } from "../api/esslApi";
import Navi from "./Navi";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CourseForm = () => {
  useEffect(() => {
    const wo = new wow();
    wo.init();
    placeholder();
    window.scrollTo(0, 0);
    console.log(query.get("type"));
  }, []);
  const query = useQuery();
  const [juser, setUser] = useState({});
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const placeholder = () => {
    setInfo(null);
    setLoading(false);
  };
  const _change = (e) => {
    const { value, id } = e.target;
    const newUser = { ...juser };
    newUser[id] = value;
    setUser(newUser);
  };

  const _submit = async () => {
    setLoading(true);
    const { email, firstname, lastname, body } = juser;
    const pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    if (email === "" || email === null) {
      setInfo({ type: "warning", msg: "Email is required!" });
      window.scrollTo(0, 0);
      setLoading(false);
      return;
    }
    if (!pattern.test(email)) {
      setInfo({ type: "error", msg: "Email is invalid!" });
      window.scrollTo(0, 0);
      setLoading(false);
      return;
    }
    const auser = {
      email,
      firstname,
      lastname,
      type: query.get("type"),
      message: body,
    };
    if ("email" in auser) {
      const response = await esslCourse(auser);
      if (response?.data) {
        setInfo({
          type: "success",
          msg: "Thank you for your interest in our course training. We will get back to you shortly.",
        });
        setLoading(false);
        setUser({});
        window.scrollTo(0, 0);
        return;
      }
      window.scrollTo(0, 0);
      setInfo({
        type: "error",
        msg: "Something went wrong. Please try again later.",
      });
      setLoading(false);
      return;
    }
  };
  const closeToast = () => setInfo(null);
  return (
    <section id="contact" className="contact-section">
      <Helmet>
        <title>ESSL - Software Engineers | Website Developers</title>
        <meta
          name="description"
          content="Contact for Everlasting Systems and Solutions LLC"
        ></meta>
        <meta
          name="keywords"
          content="web, mobile, applications, software development, contact ESSL"
        />
      </Helmet>
      <Navi navType="dark" />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="section-header text-center">
            <h2 className="wow fadeInUp" data-wow-delay=".2s">
              Hello, we are ESSL
            </h2>
            <p className="wow fadeInUp" data-wow-delay=".4s">
              Join our course training and get certified
            </p>
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <>
            {info && (
              <Alert
                severity={info?.type}
                onClose={closeToast}
                className="alertmg"
              >
                {info?.msg}
              </Alert>
            )}
            <form
              id="contact-form"
              name="contactForm"
              className="contact-form"
              data-toggle="validator"
            >
              <div id="contact-form-result"></div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control wow fadeInUp"
                      data-wow-delay=".4s"
                      name="firstname"
                      id="firstname"
                      placeholder="Firstname"
                      value={juser?.firstname || ""}
                      onChange={_change}
                      required
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control wow fadeInUp"
                      data-wow-delay=".4s"
                      name="lastname"
                      id="lastname"
                      placeholder="Lastname"
                      value={juser?.lastname || ""}
                      onChange={_change}
                      required
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <input
                  type="email"
                  className="form-control wow fadeInUp"
                  data-wow-delay=".5s"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={juser?.email || ""}
                  onChange={_change}
                  required
                />
                <div className="help-block with-errors"></div>
              </div>

              <div className="form-group">
                <textarea
                  className="form-control wow fadeInUp"
                  data-wow-delay=".8s"
                  name="message"
                  id="body"
                  placeholder="Message"
                  rows="5"
                  value={juser?.body || ""}
                  onChange={_change}
                ></textarea>
                <div className="help-block with-errors"></div>
              </div>

              <div className="form-group text-center">
                <button
                  type="submit"
                  className="btn-custom wow fadeInUp"
                  data-wow-delay="1s"
                  onClick={_submit}
                  disabled={loading}
                >
                  Send Message
                  {loading && (
                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  )}
                </button>
              </div>
            </form>
          </>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </section>
  );
};

export default CourseForm;
