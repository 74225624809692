import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./assets/css/animate.min.css";
import "./assets/css/ionicons.min.css";
import "./assets/css/linea.css";
import "./assets/css/waves.min.css";
import "./assets/css/owl.carousel.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/style.css";
import { ToastContainer } from "react-toastify";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import TagManager from "react-gtm-module";
import "react-toastify/dist/ReactToastify.css";
import Home from "./view/Home";
import Career from "./view/Career";
import Footer from "./component/Footer";

import Owope from "./view/payment/Owope";
import MainPost from "./component/blog/MainPost";
import CareerForm from "./component/CareerForm";
import { ThemeProvider } from "@material-ui/core";
import ReactGA from "react-ga";
import Domainstore from "./view/storefront/Domainstore";
import Igbo from "./view/payment/Igbo";
import { AppProvider } from "./context/AppProvider";
import { Route, Routes } from "react-router-dom";
import Privacy from "./view/dfm/Privacy";
import Videoplayer from "./view/dfm/Videoplayer";
import Blogs from "./view/Blogs";
import ScrollToTop from "./ScrollToTop";
import Pricing from "./component/Pricing";
import RegCourse from "./view/RegCourse";
import Signup from "./view/Signup";
import SignIn from "./view/SignIn";
import Contact from "./component/Contact";
import Job from "./view/Job";
import { theme } from "./assets/css/theme";
import { HomeTwoTone } from "@material-ui/icons";
import Newstaff from "./component/Newstaff";
import Africanroyalty from "./view/aroyalty/Africanroyalty";

const tagArgs = {
  gtmId: process.env.REACT_APP_GTM,
};

const NotFound = () => (
  <div className="notfound">
    <h1>Sorry, this page is not available</h1>
    <a href="/">
      Clicke here to go home
      <HomeTwoTone fontSize="100" />
    </a>
  </div>
);
function Chat() {
  return (
    <div className="App">
      <TawkMessengerReact
        propertyId={process.env.REACT_APP_TAWK}
        widgetId="default"
      />
    </div>
  );
}
function App() {
  ReactGA.initialize(process.env.REACT_APP_GA);
  TagManager.initialize(tagArgs);
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.href]);
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <ScrollToTop>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/careers" element={<Career />} />
            <Route exact path="/careers/detail/:id" element={<Job />} />
            <Route exact path="/careers/apply" element={<CareerForm />} />
            <Route exact path="/admin/staff" element={<Newstaff />} />
            <Route exact path="/course/apply" element={<RegCourse />} />
            <Route exact path="/blogs" element={<Blogs />} />
            <Route path="/blog/:id" element={<MainPost />} />
            <Route exact path="/privacy/dfm" element={<Privacy />} />
            <Route
              exact
              path="/privacy/africanroyalty"
              element={<Africanroyalty />}
            />
            <Route exact path="/success" element={<Owope />} />
            <Route exact path="/cancel" element={<Igbo />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/sign-up" element={<Signup />} />
            <Route exact path="/sign-in" element={<SignIn />} />
            <Route exact path={"/contact"} element={<Contact />} />
            <Route
              exact
              path="/store/domain/:domain"
              element={<Domainstore />}
            />
            <Route exact path="/dfm/player" element={<Videoplayer />} />
            <Route element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ScrollToTop>
        <Chat />
        <ToastContainer theme="colored" autoClose={3000} />
      </AppProvider>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
