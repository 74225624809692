import React from "react";
import { Container, CssBaseline } from "@material-ui/core";
import { Helmet } from "react-helmet";
import Navi from "../component/Navi";
import CourseForm from "../component/CourseForm";

const RegCourse = () => {
  return (
    <>
      <Helmet>
        <title>ESSL - Career</title>
        <meta
          name="description"
          content="Welcome to Everlasting Systems and Solutions LLC (ESSL)"
        ></meta>
        <meta
          name="keywords"
          content="website development in Cypress, website development in Houston,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency, software development firm, software development company, software development company, software development company, software development company, software development company, software development company, software development company, software development company, ESSL"
        />
      </Helmet>
      <CssBaseline />
      <Navi navType="dark" />
      <Container maxWidth="lg">
        <CourseForm />
      </Container>
    </>
  );
};

export default RegCourse;
