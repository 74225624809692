import React from "react";
import Navi from "./Navi";
import cloud from "../assets/img/icons/ai.png";
import mobile from "../assets/img/icons/mobile.png";
import archy from "../assets/img/icons/coding.png";
import rxt from "../assets/img/icons/consulting.png";
import javas from "../assets/img/icons/training.png";
import means from "../assets/img/icons/scratch.png";
import shadow from "../assets/img/perspective-shadow.png";
import phone from "../assets/img/perspective-phone.png";
import "./Feature.css";

const Feature = () => {
  return (
    <section id="features-4" className="features-2-section">
      <Navi navType="dark" />
      <div className="container">
        <div className="section-header text-center">
          <h2 className="wow fadeInUp" data-wow-delay=".2s">
            What We Offer
          </h2>
          <p className="wow fadeInUp" data-wow-delay=".4s">
            Let's tell the whole world about your business
          </p>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div
              className="icon-feature wow fadeInUp grow"
              data-wow-delay=".2s"
            >
              <div className="icon">
                <img src={cloud} alt="cloud api" />
              </div>

              <div className="content">
                <h4>DATA</h4>
                <ul>
                  <li>Analytics</li>
                  <li>Business Intelligenece Reporting</li>
                  <li>Data Cleaning</li>
                  <li>Machine Learning</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="icon-feature wow fadeInUp grow"
              data-wow-delay=".4s"
            >
              <div className="icon">
                <img src={mobile} alt="mobile dev" />
              </div>

              <div className="content">
                <h4>Mobile</h4>
                <ul>
                  <li>Kotlin</li>
                  <li>Swift</li>
                  <li>ReactNative</li>
                  <li>Flutter</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="icon-feature wow fadeInUp grow"
              data-wow-delay=".6s"
            >
              <div className="icon">
                <img src={archy} alt="Archy" />
              </div>

              <div className="content">
                <h4>Software Development </h4>
                <ul>
                  <li>Process Mapping</li>
                  <li>Requirements Gathering</li>
                  <li>Discovery and Framing</li>
                  <li>UI/UX Development</li>
                  <li>Fullstack Development</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div
              className="icon-feature wow fadeInUp grow"
              data-wow-delay=".2s"
            >
              <div className="icon">
                <img src={rxt} alt="react dev" />
              </div>
              <div className="content">
                <h4>CONSULTING</h4>
                <ul>
                  <li>Software Development</li>
                  <li>Product Development</li>
                  <li>Data Analytics</li>
                  <li>Market Search</li>
                  <li>Machine Learning & AI</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="icon-feature wow fadeInUp grow"
              data-wow-delay=".4s"
            >
              <div className="icon">
                <img src={javas} />
              </div>
              <div className="content">
                <h4>TRAINING</h4>
                <ul>
                  <li>Microservices</li>
                  <li>Cybersecurity</li>
                  <li>Information System Management</li>
                  <li>Custom Software Solutions</li>
                  <li>Reporting </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="icon-feature wow fadeInUp grow"
              data-wow-delay=".6s"
            >
              <div className="icon">
                <img src={means} alt="mean stack" />
              </div>
              <div className="content">
                <h4>KID BOOTCAMP</h4>
                <ul>
                  <li>Sketch</li>
                  <li>Minecraft</li>
                  <li>Game Development</li>
                  <li>Career Guide</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="mockup">
          <img className="wow zoomIn animated" src={shadow} alt="shadow" />
          <img
            className="phone wow fadeInDown animated"
            src={phone}
            alt="phone"
          />
        </div>
      </div>
    </section>
  );
};

export default Feature;
