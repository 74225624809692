import React, { useState } from "react";
import { Container, CssBaseline, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import Navi from "../component/Navi";
import wow from "wow.js";
import SearchIcon from "@material-ui/icons/Search";
const Career = () => {
  const [searchQuery, setSearchQuery] = useState("");
  React.useEffect(() => {
    window.scrollTo(0, 0);
    const wo = new wow();
    wo.init();
  }, []);
  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredJobs = openings.filter((job) => {
    const title = job.title.toLowerCase();
    const searchQueryLower = searchQuery.toLowerCase();
    return title.includes(searchQueryLower);
  });
  return (
    <>
      <Helmet>
        <title>ESSL - Vacancies</title>
        <meta
          name="description"
          content="Welcome to Everlasting Systems and Solutions LLC (ESSL) careers"
        ></meta>
        <meta
          name="keywords"
          content="website development in Cypress, website development in Houston,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency, software development firm, software development company, software development company, software development company, software development company, software development company, software development company, software development company, software development company,vacancies,tech jobs, ESSL"
        />
      </Helmet>
      <CssBaseline />
      <Navi navType="dark" />
      <Container maxWidth="lg">
        <div id="jobs" className="mt-5 mb-5 parallax">
          <div className="mt-5 mb-5 parallax section-header text-center d-flex flex-column justify-content-center align-items-center">
            <h2 className="wow fadeInUp  " data-wow-delay=".2s">
              Working at ESSL
            </h2>
            <div className="w-75 mt-5 text-center">
              <p className="wow slideInLeft fs-3" data-wow-delay=".2s">
                Across the company, we are excited about delivering a product
                that our customers love. We promote a healthy work-life balance,
                career growth, and friendly remote culture.
              </p>
            </div>
          </div>
          <div className="section-header text-center">
            <h2 className="wow fadeInUp" data-wow-delay=".2s">
              Jobs
            </h2>
            <p className="wow fadeInUp" data-wow-delay=".4s">
              Recent openings
            </p>
          </div>
          <div className="d-flex form-control justify-content-between align-items-center my-3">
            <input
              type="text"
              placeholder="Search Jobs"
              className="border-0 w-100 form-control py-0 flex-4"
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
            <div className="cursor-pointer ">
              <SearchIcon size={30} />
            </div>
          </div>
          <div className="row mb-5 justify-content-center align-items-center">
            {filteredJobs.map((job, index) => (
              <JobCards key={index} job={job} />
            ))}
          </div>
          <div>
            <button
              className="form-control btn-custom wow fadeInUp w-100 my-3 badge-color"
              data-wow-delay=".4s"
            >
              View More Jobs
            </button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Career;

export const JobCards = ({ job }) => {
  return (
    <>
      <div className="col-md-4">
        <div
          className={`p-table wow ${
            Math.random() < 0.5 ? "fadeInRight" : "fadeInLeft"
          }`}
          data-wow-delay=".8s"
        >
          <div>
            <p className="fw-bold">{job.title}</p>
            <p className="fw-normal">{job.description}</p>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            {job?.status !== "closed" ? (
              <>
                <a
                  href={`/careers/detail/${job.id}`}
                  className="text-decoration-none badge p-2 bg-primary text-wrap text-white"
                >
                  Read more
                </a>

                <a
                  href={`/careers/apply?jobType=${job.jobType}`}
                  className="text-decoration-none badge text-wrap p-2 badge-color btn-custom"
                >
                  Apply
                </a>
              </>
            ) : (
              <Typography variant="caption">Closed</Typography>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const openings = [
  {
    id: "fd1",
    title: "Frontend Developer",
    jobType: "SOFTWARE_ENGINEER",
    description:
      "We are looking for a frontend developer specializing on React and React Native Development",
    responsibilities: [],
    expectations: [],
    status: "closed",
  },
  {
    id: "pm1",
    title: "Product Manager",
    jobType: "PRODUCT_MANAGER",
    description:
      "We are seeking a highly motivated and skilled individual to join our team as a Technical Sales/Product Manager. This position is a unique blend of technical expertise, sales acumen, and product management responsibilities. As a Technical Sales/Product Manager, you will play a crucial role in driving the success of our products by effectively bridging the gap between our engineering teams and our clients.",
    responsibilities: [
      {
        title: "Product Management",
        description:
          "Collaborate with the product development teams to define product roadmaps, features, and enhancements. Gather and analyze market insights, customer feedback, and industry trends to continuously improve our offerings. Ensure that our products meet customer needs and align with business goals",
      },
      {
        title: "Technical Expertise",
        description:
          "Possess a deep understanding of our products' technical aspects and capabilities. Be the go-to person for technical inquiries and effectively communicate complex technical concepts to both technical and non-technical stakeholders.",
      },
      {
        title: "Sales Support",
        description:
          "Work closely with our sales team to provide technical expertise during pre-sales activities, including product demonstrations, presentations, and proposal development. Understand customer requirements and tailor product solutions to meet their specific needs.",
      },
      {
        title: "Client Relationship Management",
        description:
          "Build and maintain strong relationships with key clients. Understand their pain points and requirements, and proactively address their needs. Act as a trusted advisor, offering technical insights and solutions to drive customer satisfaction and retention.",
      },
      {
        title: "Market Research",
        description:
          "Conduct thorough market research and competitive analysis to identify market opportunities, potential partnerships, and areas for growth. Stay up-to-date with industry trends and technological advancements that could impact our products' positioning.",
      },
      {
        title: "Project Coordination",
        description:
          "Oversee the successful implementation of product initiatives, working closely with cross-functional teams, including engineering, marketing, and operations. Ensure timely delivery and high-quality output.",
      },
    ],
    expectations: [
      "Bachelor's degree in a technical field, such as Computer Science, Engineering, or a related discipline. An MBA or business-related degree is a plus.",
      "Proven experience in technical sales, product management, or a similar role, preferably within the technology industry.",
      "Strong technical acumen with the ability to understand complex technical concepts and communicate them effectively.",
      "Excellent communication and presentation skills, with the ability to interact with diverse stakeholders, including clients, sales teams, and engineering teams.",
      "Analytical mindset with a data-driven approach to decision-making. Ability to gather and interpret data to drive product improvements and business growth.",
      "Entrepreneurial spirit with a passion for innovation and driving customer success.",
      "Strong leadership skills with the ability to lead and motivate cross-functional teams.",
      "Results-oriented, self-motivated, and able to work in a fast-paced, dynamic environment.",
    ],
    closure:
      "Join our dynamic team and make a significant impact on the success of our cutting-edge products. If you are a driven individual with a passion for technology and sales, we would love to hear from you. Apply now to be part of our exciting journey!",
  },
  {
    id: "po2",
    title: "Product Owner",
    jobType: "PRODUCT_OWNER",
    responsibilities: [],
    expectations: [],
    description:
      "We are seeking a talented individual to take ownership of product management across our teams.",
    status: "closed",
  },
];
