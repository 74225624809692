import { Container, CssBaseline, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import Navi from "../component/Navi";
import {
  DocumentUploadInput,
  DocumentUploadItem,
} from "../component/DocumentUpload";
import { Alert } from "@material-ui/lab";
import wow from "wow.js";
import S3 from "react-aws-s3";
import { notifyError, notifySuccess } from "./notification";
import { submitApplication } from "../api/esslApi";
import { useLocation, useNavigate } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const config = {
  bucketName: process.env.REACT_APP_bucket,
  dirName: "cv",
  region: "us-east-1",
  accessKeyId: process.env.REACT_APP_aws_key,
  secretAccessKey: process.env.REACT_APP_aws_secret,
  s3Url: "https://essluploads.s3.amazonaws.com",
};
const ReactS3Client = new S3(config);

const CareerForm = () => {
  const query = useQuery();
  const navigate = useNavigate();

  let setJobType = {
    name: "Software Engineer",
    value: "SOFTWARE_ENGINEER",
  };

  if (query.get("jobType") === "PRODUCT_MANAGER") {
    setJobType = {
      name: "Product Manager",
      value: "PRODUCT_MANAGER",
    };
  } else if (query.get("jobType") === "PRODUCT_OWNER") {
    setJobType = {
      name: "Product Owner",
      value: "PRODUCT_OWNER",
    };
  }

  const [applicant, setApplicant] = React.useState({
    fullname: "",
    email: "",
    phone: "",
    files: [],
    jobType: setJobType?.value,
  });
  const [loading, setLoading] = React.useState(false);
  const [info, setInfo] = React.useState(null);

  React.useEffect(() => {
    const wo = new wow();
    wo.init();
  }, []);

  const _change = (e) => {
    const { value, id } = e.target;
    const newApplicant = { ...applicant };
    newApplicant[id] = value;
    setApplicant(newApplicant);
  };

  const _upload = async (files) => {
    ReactS3Client.uploadFile(files[0], files[0].name)
      .then(() => {
        const newApplicant = { ...applicant };
        newApplicant.files.push(files[0].name);
        setApplicant(newApplicant);

        notifySuccess(
          "Document Uploaded successfully, go ahead and complete the form."
        );
      })
      .catch((err) => {
        console.error(err);
        notifyError("File not uploaded, Something went wrong!");
      });
  };

  const _deleteFile = (filename) => {
    const newApplicant = { ...applicant };
    newApplicant.files.splice(newApplicant.files.indexOf(filename), 1);
    setApplicant(newApplicant);

    notifySuccess("File deleted successfully");
  };

  const closeToast = () => setInfo(null);

  const _submit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const { email, fullname, phone, files } = applicant;

    const pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

    if (fullname === "" || fullname === null) {
      notifyError("Full Name is required is required!");
      window.scroll(0, 0);
      setLoading(false);
      return;
    }
    if (phone === "" || phone === null) {
      notifyError("Phone is required is required!");
      window.scroll(0, 0);
      setLoading(false);
      return;
    }
    if (files === null || files.length === 0) {
      notifyError("Please upload your resume!");
      window.scroll(0, 0);
      setLoading(false);
      return;
    }
    if (email === "" || email === null) {
      notifyError("Email is required!");
      window.scroll(0, 0);
      setLoading(false);
      return;
    }
    if (!pattern.test(email)) {
      notifyError("Email is invalid!");
      window.scroll(0, 0);
      setLoading(false);
      return;
    }

    const response = await submitApplication(applicant);

    if (response.error) {
      notifyError("Form not submitted, something went wrong");
      setLoading(false);
      return;
    }
    notifySuccess("Application submitted successfully!");
    setApplicant({
      fullname: "",
      email: "",
      phone: "",
      files: [],
      jobType: setJobType.value,
    });
    setLoading(false);
    navigate("/careers");
  };
  return (
    <>
      <Helmet>
        <title>ESSL - Careers</title>
        <meta
          name="description"
          content="Welcome to Everlasting Systems and Solutions LLC (ESSL)"
        ></meta>
        <meta
          name="keywords"
          content="website development in Cypress, website development in Houston,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency, software development firm, software development company, software development company, software development company, software development company, software development company, software development company, software development company, software development company,tech jobs, ESSL"
        />
      </Helmet>
      <CssBaseline />
      <Navi navType="dark" />
      <Container maxWidth="lg">
        <div className="main-section-nobg parallax">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="section-header text-center">
                <Typography
                  variant="h2"
                  className="wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  Apply for this position
                </Typography>
                <p className="wow fadeInUp" data-wow-delay=".4s">
                  Fill in the required fields to be eligible for this position
                </p>
              </div>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <form
                id="career-form"
                name="careerForm"
                className="contact-form"
                data-toggle="validator"
              >
                {info && (
                  <Alert
                    severity={info?.type}
                    onClose={closeToast}
                    className="alertmg"
                  >
                    {info?.msg}
                  </Alert>
                )}

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control wow fadeInUp"
                    data-wow-delay=".4s"
                    name="name"
                    id="fullname"
                    placeholder="Fullname (Required)"
                    value={applicant?.fullname || ""}
                    onChange={_change}
                    required
                  />
                  <div className="help-block with-errors"></div>
                </div>

                <div className="form-group">
                  <input
                    type="email"
                    className="form-control wow fadeInUp"
                    data-wow-delay=".4s"
                    name="email"
                    id="email"
                    placeholder="Email  (Required)"
                    value={applicant?.email || ""}
                    onChange={_change}
                    required
                  />
                  <div className="help-block with-errors"></div>
                </div>

                <div className="form-group">
                  <input
                    type="tel"
                    className="form-control wow fadeInUp"
                    data-wow-delay=".4s"
                    name="name"
                    id="phone"
                    placeholder="Phone (Required)"
                    value={applicant?.phone || ""}
                    onChange={_change}
                    required
                  />
                  <div className="help-block with-errors"></div>
                </div>

                <div className="form-group">
                  <select
                    className="form-control wow fadeInUp"
                    data-wow-delay=".4s"
                    value={applicant?.jobType || ""}
                    onChange={_change}
                    id="jobType"
                    disabled
                    required
                  >
                    <option value={setJobType.value}>{setJobType.name}</option>
                  </select>
                  <div className="help-block with-errors"></div>
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control wow fadeInUp"
                    data-wow-delay=".4s"
                    name="name"
                    id="address"
                    placeholder="Permanent Home Address"
                    value={applicant?.address || ""}
                    onChange={_change}
                  />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group wow fadeInUp" data-wow-delay=".4s">
                  <label className="text-black fs-6 fw-semibold mb-1">
                    Resume/CV or other Documents(Required)
                  </label>
                  <div className="mb-3">
                    {applicant?.files.length < 3 && (
                      <DocumentUploadInput
                        dropzoneOpt={{
                          maxFiles: 1,
                          accept: "image/*,.pdf,.docx,.doc",
                          onDrop: (acceptedFile) => {
                            _upload(acceptedFile);
                          },
                          noClick: true,
                          noKeyboard: true,
                        }}
                        title="Resume/CV"
                      />
                    )}

                    {applicant?.files.map((file, index) => (
                      <DocumentUploadItem
                        key={index}
                        label={file}
                        onClick={() => {
                          _deleteFile(file);
                        }}
                      />
                    ))}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control wow fadeInUp"
                        data-wow-delay=".4s"
                        name="name"
                        id="social"
                        placeholder="LinkedIn or Twitter link"
                        value={applicant?.social || ""}
                        onChange={_change}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control wow fadeInUp"
                        data-wow-delay=".4s"
                        name="name"
                        id="portfolio"
                        placeholder="Link to any portfolio, behance, github"
                        value={applicant?.portfolio || ""}
                        onChange={_change}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                </div>
                <div className="form-group text-center">
                  <button
                    type="submit"
                    className="btn-custom wow fadeInUp w-100"
                    data-wow-delay=".5s"
                    onClick={_submit}
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Submit"}
                    {loading && (
                      <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    )}
                  </button>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default CareerForm;
