import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { NavHashLink } from "react-router-hash-link";

import React from "react";
import { CardFooter } from "reactstrap";
const useStyles = makeStyles((theme) => ({
  cardMedia: {
    flex: 1,
    maxWidth: "100%",
    objectFit: "cover",
    height: 200,
  },
  cardDetails: {
    flex: 2,
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginBottom: "0.5rem",
  },
  brief: {
    marginBottom: "1rem",
  },
  readMore: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  badgeContainer: {
    marginTop: "auto", // Push the badgeContainer to the bottom
    display: "flex",
    flexWrap: "wrap",
    gap: "0.5rem",
  },
}));
const FeaturedPost = (props) => {
  const classes = useStyles();
  const { post } = props;

  return (
    <Grid item md={6} className={classes.cardW}>
      <CardActionArea component={NavHashLink} to={`/blog/${post.id}`}>
        <Card
          sx={{
            boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
            border: "1px solid #e0e0e0",
            transition: "transform 0.15s ease-in-out",
            "&:hover": {
              transform: "scale3d(1.05, 1.05, 1)",
            },
          }}
        >
          <CardMedia
            className={classes.cardMedia}
            component="img"
            image={
              post?.thumb
                ? `/img/${post.thumb}`
                : `https://via.placeholder.com/400x200?text=No-Image`
            }
            title={post?.imgText}
            alt="blog image"
          />
          <div className={classes.cardDetails}>
            <CardContent
              sx={{
                transition: "transform 0.15s ease-in-out",
                "&:hover": { transform: "translateY(-4px)" },
              }}
            >
              <Typography className={classes.title} variant="h5">
                {post?.title}
              </Typography>
              <Typography className={classes.subtitle} variant="subtitle1">
                {post?.date}
              </Typography>
              <Typography
                className={classes.brief}
                variant="subtitle1"
                paragraph
              >
                {post?.brief && post.brief.length > 100
                  ? `${post.brief.slice(0, 150)}...`
                  : post?.brief}
              </Typography>
              <Typography className={classes.readMore} variant="subtitle1">
                Read more...
              </Typography>
            </CardContent>
            <CardFooter>
              <Box className={classes.badgeContainer}>
                {post.category &&
                  post.category.map((cat, index) => (
                    <Chip
                      key={index}
                      label={cat}
                      size="small"
                      sx={{
                        backgroundColor: "#f0f0f0",
                        fontWeight: "bold",
                      }}
                    />
                  ))}
              </Box>
            </CardFooter>
          </div>
        </Card>
      </CardActionArea>
    </Grid>
  );
};

export default FeaturedPost;
