import React, { useEffect, useState } from "react";
import loader from "../assets/img/loader.gif";
import Intro from "../component/home/intro";
import Feature from "../component/Feature";
import Contact from "../component/Contact";
import Solution from "../component/Solution";
import wow from "wow.js";
import Navi from "../component/Navi";
import { Helmet } from "react-helmet";
import Blogsection from "../component/blog/Blogsection";

const Home = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    const wo = new wow();
    wo.init();
  }, []);

  return (
    <div>
      <Helmet>
        <title>ESSL - Everlasting Systems and Solutions. Home</title>
        <meta
          name="description"
          content="Welcome to Everlasting Systems and Solutions LLC (ESSL)"
        ></meta>
        <meta
          name="keywords"
          content="website development in Cypress, website development in Houston,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency, software development firm, software development company, software development company, software development company, software development company, software development company, software development company, software development company, software development company, ESSL"
        />
      </Helmet>
      {loading ? (
        <div id="preloader">
          <div className="loader">
            <img src={loader} alt="loader" />
          </div>
        </div>
      ) : (
        <>
          <Navi />
          <div id="home">
            <Intro />
          </div>
          <div id="feature">
            <Feature />
          </div>

          <div id="solution">
            <Solution />
          </div>
          {/* <div id="pricing">
            <Pricing />
          </div> */}
          <div id="blog">
            <Blogsection />
          </div>
          <div id="contact">
            <Contact />
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
