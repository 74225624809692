import React from "react";
import { Container, CssBaseline } from "@material-ui/core";
import { Helmet } from "react-helmet";
import Navi from "../../component/Navi";

const Africanroyalty = () => {
  return (
    <>
      <Helmet>
        <title>ESSL - Home</title>
        <meta
          name="description"
          content="Welcome to Everlasting Systems and Solutions LLC (ESSL)"
        ></meta>
        <meta
          name="keywords"
          content="website development in Cypress, website development in Houston,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency, software development firm, software development company, software development company, software development company, software development company, software development company, software development company, software development company, software development company, ESSL"
        />
      </Helmet>
      <CssBaseline />
      <Navi navType="dark" />
      <Container>
        <div className="row main-section-nobg parallax" id="intro">
          <div className="container">
            {" "}
            <h1>Privacy Policy - African Royalty Designs</h1>{" "}
            <p>
              This privacy policy explains how African Royalty Designs collects,
              uses, and protects your personal data when you browse or purchase
              items on our online clothing store.
            </p>{" "}
            <h3>What Data We Collect</h3>{" "}
            <p>We may collect the following information:</p>{" "}
            <ul>
              {" "}
              <li>
                Contact details like your name, email address, and phone numbers
              </li>{" "}
              <li>Payment and billing information like credit card number</li>{" "}
              <li>Account login credentials</li>{" "}
              <li>Purchase history and saved items</li>{" "}
              <li>Website usage data like pages visited and items viewed</li>{" "}
            </ul>{" "}
            <h3>Why We Need Your Data</h3> <p>We use your data to:</p>{" "}
            <ul>
              {" "}
              <li>Process orders and send you purchase confirmations</li>{" "}
              <li>
                Manage your account registration and handle returns/exchanges
              </li>{" "}
              <li>Understand customer behavior to improve our store</li>{" "}
              <li>
                Send promotional emails about sales or new arrivals you may like
              </li>{" "}
            </ul>{" "}
            <h3>Keeping Your Data Safe</h3>{" "}
            <p>
              We implement security best practices like encryption to safeguard
              your personal data. Only authorized staff can access the
              information.
            </p>{" "}
            <h3>External Services</h3>{" "}
            <p>
              We do not sell or share your data with any external parties,
              except to process payments and deliver orders.
            </p>{" "}
            <p>
              Please review privacy policies for payment gateways like PayPal
              when providing payment information.
            </p>{" "}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Africanroyalty;
