import React from "react";
import { DocumentUploadInput, DocumentUploadItem } from "./DocumentUpload";
import { Alert } from "@material-ui/lab";
import { Container, CssBaseline, Grid, Typography } from "@material-ui/core";
import S3 from "react-aws-s3";
import { notifyError, notifySuccess } from "./notification";
import { Helmet } from "react-helmet";
import Navi from "./Navi";
import { addStaff } from "../api/esslApi";
window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
  bucketName: process.env.REACT_APP_bucket,
  dirName: "cv",
  region: "us-east-1",
  accessKeyId: process.env.REACT_APP_aws_key,
  secretAccessKey: process.env.REACT_APP_aws_secret,
  s3Url: "https://essluploads.s3.amazonaws.com",
};
const ReactS3Client = new S3(config);
const Newstaff = () => {
  const [loading, setLoading] = React.useState(false);
  const [applicant, setApplicant] = React.useState({
    fullname: "",
    email: "",
    phone: "",
    files: [],
    employer1CompanyName: "",
    employer1CompanyAddress: "",
    employer1CompanyContact: "",
    employer1Phone: "",
    employer2CompanyName: "",
    employer2CompanyAddress: "",
    employer2CompanyContact: "",
    employer2Phone: "",
    referee1fullName: "",
    referee1Address: "",
    referee1Phone: "",
    referee1Email: "",
    referee2FullName: "",
    referee2Address: "",
    referee2Phone: "",
    referee2Email: "",
  });
  const [info, setInfo] = React.useState(null);
  const closeToast = () => setInfo(null);
  const _deleteFile = (filename) => {
    const newApplicant = { ...applicant };
    newApplicant.files.splice(newApplicant.files.indexOf(filename), 1);
    setApplicant(newApplicant);

    notifySuccess("File deleted successfully");
  };
  const _change = (e) => {
    const { value, id } = e.target;
    const newApplicant = { ...applicant };
    newApplicant[id] = value;
    setApplicant(newApplicant);
  };
  const _upload = async (files) => {
    console.log("the files", files);
    if (!files[0].type.includes("application")) {
      notifyError("File Type must be a PDF, DOCX or DOC");
      return;
    }
    if (files[0].size > 558493) {
      notifyError("File can't be larger than 5mb!");
      return;
    }

    ReactS3Client.uploadFile(files[0], files[0].name)
      .then(() => {
        const newApplicant = { ...applicant };
        newApplicant.files.push(files[0].name);
        setApplicant(newApplicant);

        notifySuccess(
          "Document Uploaded successfully, go ahead and complete the form."
        );
      })
      .catch((err) => {
        console.error(err);
        notifyError("File not uploaded, Something went wrong!");
      });
  };
  const _submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await addStaff(applicant);
    if (response.error) {
      notifyError("Form not submitted, something went wrong");
      setLoading(false);
      return;
    }
    notifySuccess("Staff account created successfully!");
    setApplicant({
      fullname: "",
      email: "",
      phone: "",
      files: [],
      employer1CompanyName: "",
      employer1CompanyAddress: "",
      employer1CompanyContact: "",
      employer1Phone: "",
      employer2CompanyName: "",
      employer2CompanyAddress: "",
      employer2CompanyContact: "",
      employer2Phone: "",
      referee1fullName: "",
      referee1Address: "",
      referee1Phone: "",
      referee1Email: "",
      referee2FullName: "",
      referee2Address: "",
      referee2Phone: "",
      referee2Email: "",
    });
    setLoading(false);
  };
  return (
    <>
      <Helmet>
        <title>ESSL - Careers</title>
        <meta
          name="description"
          content="Everlasting Systems and Solutions LLC (ESSL). New Staff"
        ></meta>
        <meta
          name="keywords"
          content="website development in Cypress, website development in Houston,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency, software development firm, software development company, software development company, software development company, software development company, software development company, software development company, software development company, software development company,tech jobs, ESSL"
        />
      </Helmet>
      <CssBaseline />
      <Navi navType="dark" />
      <Container maxWidth="lg">
        <div className="main-section-nobg parallax">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="section-header text-center">
                <Typography
                  variant="h2"
                  className="wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  Welcome to our team
                </Typography>
                <p className="wow fadeInUp" data-wow-delay=".4s">
                  Please provide your information
                </p>
              </div>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <form
                id="career-form"
                name="careerForm"
                className="contact-form"
                data-toggle="validator"
              >
                {info && (
                  <Alert
                    severity={info?.type}
                    onClose={closeToast}
                    className="alertmg"
                  >
                    {info?.msg}
                  </Alert>
                )}

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control wow fadeInUp"
                    data-wow-delay=".4s"
                    name="name"
                    id="fullname"
                    placeholder="Fullname (Required)"
                    value={applicant?.fullname || ""}
                    onChange={_change}
                    required
                  />
                  <div className="help-block with-errors"></div>
                </div>

                <div className="form-group">
                  <input
                    type="email"
                    className="form-control wow fadeInUp"
                    data-wow-delay=".4s"
                    name="email"
                    id="email"
                    placeholder="Email  (Required)"
                    value={applicant?.email || ""}
                    onChange={_change}
                    required
                  />
                  <div className="help-block with-errors"></div>
                </div>

                <div className="form-group">
                  <input
                    type="tel"
                    className="form-control wow fadeInUp"
                    data-wow-delay=".4s"
                    name="name"
                    id="phone"
                    placeholder="Phone (Required)"
                    value={applicant?.phone || ""}
                    onChange={_change}
                    required
                  />
                  <div className="help-block with-errors"></div>
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control wow fadeInUp"
                    data-wow-delay=".4s"
                    name="name"
                    id="address"
                    placeholder="Permanent Home Address (Required)"
                    value={applicant?.address || ""}
                    onChange={_change}
                  />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control wow fadeInUp"
                    name="acct"
                    id="acct"
                    placeholder="Account Number"
                    value={applicant?.acct || ""}
                    onChange={_change}
                  />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control wow fadeInUp"
                    name="bank"
                    id="bank"
                    placeholder="Bank"
                    value={applicant?.bank || ""}
                    onChange={_change}
                  />
                  <div className="help-block with-errors"></div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control wow fadeInUp"
                    name="dob"
                    id="dob"
                    placeholder="Date of Birth (Required)"
                    value={applicant?.dob || ""}
                    onChange={_change}
                  />
                  <div className="help-block with-errors"></div>
                </div>

                {/* Employer Information - Employer 1 */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    Most Recent Employer 1
                  </Typography>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Company Name"
                      name="employer1CompanyName"
                      id="employer1CompanyName"
                      value={applicant?.employer1CompanyName || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Company Address"
                      name="employer1CompanyAddress"
                      id="employer1CompanyAddress"
                      value={applicant?.employer1CompanyAddress || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Contact Person"
                      name="employer1CompanyContact"
                      id="employer1CompanyContact"
                      className="form-control"
                      value={applicant?.employer1CompanyContact || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Contact Phone"
                      name="employer1Phone"
                      id="employer1Phone"
                      value={applicant?.employer1Phone || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    Most Recent Employer 2
                  </Typography>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Company Name"
                      name="employer2CompanyName"
                      id="employer2CompanyName"
                      value={applicant?.employer2CompanyName || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Company Address"
                      name="employer2CompanyAddress"
                      id="employer2CompanyAddress"
                      value={applicant?.employer2CompanyAddress || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Contact Person"
                      name="employer2CompanyContact"
                      id="employer2CompanyContact"
                      value={applicant?.employer2CompanyContact || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Contact Phone"
                      name="employer2Phone"
                      id="employer2Phone"
                      value={applicant?.employer2Phone || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                </Grid>

                {/* Referee Information - Referee 1 */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    Referee 1
                  </Typography>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name"
                      name="referee1fullName"
                      id="referee1fullName"
                      value={applicant?.referee1fullName || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Address"
                      name="referee1Address"
                      id="referee1Address"
                      value={applicant?.referee1Address || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Phone"
                      name="referee1Phone"
                      id="referee1Phone"
                      value={applicant?.referee1Phone || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Email"
                      type="email"
                      name="referee1Email"
                      id="referee1Email"
                      value={applicant?.referee1Email || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                </Grid>
                {/* Referee Information - Referee 2 */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    Referee 2
                  </Typography>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Full Name"
                      name="referee2FullName"
                      id="referee2FullName"
                      value={applicant?.referee2FullName || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Address"
                      name="referee2Address"
                      id="referee2Address"
                      value={applicant?.referee2Address || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Phone"
                      name="referee2Phone"
                      id="referee2Phone"
                      value={applicant?.referee2Phone || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Email"
                      name="referee2Email"
                      id="referee2Email"
                      value={applicant?.referee2Email || ""}
                      onChange={_change}
                      required
                    />
                  </div>
                </Grid>

                <div className="form-group wow fadeInUp" data-wow-delay=".4s">
                  <label className="text-black fs-6 fw-semibold mb-1">
                    Government Issued ID(Required)
                  </label>
                  <div className="mb-3">
                    {applicant?.files.length < 3 && (
                      <DocumentUploadInput
                        dropzoneOpt={{
                          maxFiles: 1,
                          accept: [".pdf", ".docx", "doc"].join(","),
                          onDrop: (acceptedFile) => {
                            _upload(acceptedFile);
                          },
                          noClick: true,
                          noKeyboard: true,
                        }}
                        title="Resume/CV"
                      />
                    )}

                    {applicant?.files.map((file, index) => (
                      <DocumentUploadItem
                        key={index}
                        label={file}
                        onClick={() => {
                          _deleteFile(file);
                        }}
                      />
                    ))}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control wow fadeInUp"
                        data-wow-delay=".4s"
                        name="name"
                        id="social"
                        placeholder="LinkedIn or Twitter link"
                        value={applicant?.social || ""}
                        onChange={_change}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control wow fadeInUp"
                        data-wow-delay=".4s"
                        name="name"
                        id="portfolio"
                        placeholder="Link to any portfolio, behance, github"
                        value={applicant?.portfolio || ""}
                        onChange={_change}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                </div>
                <div className="form-group text-center">
                  <button
                    type="submit"
                    className="btn-custom wow fadeInUp w-100"
                    data-wow-delay=".5s"
                    onClick={_submit}
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Submit"}
                    {loading && (
                      <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    )}
                  </button>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default Newstaff;
