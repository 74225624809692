import React, { useState, useEffect } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CssBaseline,
  Grid,
  IconButton,
  TextField,
  Typography,
  Button,
  LinearProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { getDomain, handlePay } from "../../api/esslApi";
import { Helmet } from "react-helmet";
import Navi from "../../component/Navi";
import CreditCardIcon from "@material-ui/icons/CreditCard";
const Domainstore = (props) => {
  const [domain, setDomain] = useState(null);
  const [loading, setLoading] = useState(false);
  const myDomain = async () => {
    const domainProperty = await getDomain(props.domain);
    setDomain(domainProperty);
  };
  // or use domain
  useEffect(() => {
    myDomain();
  }, [props.domain]);
  const _pay = () => {
    setLoading(true);
    const description = `Domain registrationm for ${domain?.id}`;
    handlePay({ description, amount: parseInt(+domain?.cost * 100) });
    setLoading(false);
  };
  return (
    <section id="contact" className="contact-section">
      <Helmet>
        <title>ESSL - Checkout</title>
        <meta
          name="description"
          content="Welcome to Everlasting Systems and Solutions LLC (ESSL)"
        ></meta>
        <meta
          name="keywords"
          content="website development in Cypress, website development in Houston,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency, software development firm, software development company, software development company, software development company, software development company, software development company, software development company, software development company, software development company, ESSL"
        />
      </Helmet>
      <CssBaseline />
      <Navi navType="dark" />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="section-header text-center">
            <Typography
              variant="h2"
              className="wow fadeInUp"
              data-wow-delay=".2s"
            >
              {`${domain?.product || "Product"} Renewal for ${domain?.id}`}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={2}></Grid>

        <Grid item xs={8}>
          {domain && "owner" in domain ? (
            <div>
              <Grid item xs={12}>
                {loading && <LinearProgress />}
                <TextField
                  id="sdomain"
                  variant="outlined"
                  style={{ width: "90%" }}
                  placeholder="Search for a domain"
                />
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </Grid>

              <Card style={{ width: "90%", marginTop: 15 }}>
                <CardContent>
                  <Typography variant="body1">{`Registrant: ${domain?.name}`}</Typography>
                  <Typography
                    variant="subtitle1"
                    color="error"
                  >{`Cost: ${domain?.cost}`}</Typography>
                </CardContent>
                <CardActions>
                  <Button color="secondary" onClick={_pay}>
                    Make Payment <CreditCardIcon />
                  </Button>
                </CardActions>
              </Card>
            </div>
          ) : (
            <div>
              <Typography variant="h3">
                I am unable to fetch the product, please refresh the page
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </section>
  );
};
export default Domainstore;
