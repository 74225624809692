import {
  Card,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CheckIcon from "@material-ui/icons/Check";
import { Container } from "reactstrap";
import Navi from "../../component/Navi";

const useStyles = makeStyles(() => ({
  cardBox: {
    width: "50%",
    margin: "0 auto",
    marginTop: 30,
  },
  cardDetail: { display: "flex", flexDirection: "row" },
  cancel: { fontSize: 80, textAlign: "center" },
}));
const Owope = () => {
  const classes = useStyles();
  const [setStatus] = useState(null);
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setStatus("SUCCESS");
    }
    if (query.get("canceled")) {
      setStatus("CANCELED");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>ESSL - Home</title>
        <meta
          name="description"
          content="Welcome to Everlasting Systems and Solutions LLC (ESSL)"
        ></meta>
        <meta
          name="keywords"
          content="website development in Cypress, website development in Houston,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency, software development firm, software development company, software development company, software development company, software development company, software development company, software development company, software development company, software development company, ESSL"
        />
      </Helmet>
      <section id="contact" className="contact-section">
        <Navi navType="dark" />
        <Container>
          <div className="section-header text-center">
            <Typography
              variant="h2"
              className="wow fadeInUp"
              color="primary"
              data-wow-delay=".2s"
            >
              Transaction Succesful
            </Typography>
          </div>
          <Card className={classes.cardBox}>
            <div className={classes.cardDetail}>
              <CardMedia>
                <CheckIcon color="primary" className={classes.cancel} />
              </CardMedia>
              <CardContent>
                <Typography variant="body1">
                  You transaction was processed succesfully!
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Container>
      </section>
    </>
  );
};

export default Owope;
