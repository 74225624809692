import React, { useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import {
  AppBar,
  Button,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../assets/img/logo.png";

const Navi = (props) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const { navType = "dark" } = props;

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="fixed" color={navType}>
      <Toolbar>
        <Hidden smUp>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleOpenNavMenu}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorElNav}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            <MenuItem onClick={handleCloseNavMenu}>
              <Button color="inherit" href="/">
                Home
              </Button>
            </MenuItem>
            <MenuItem onClick={handleCloseNavMenu}>
              <Button color="inherit" href="/#feature">
                What We Do
              </Button>
            </MenuItem>
            <MenuItem onClick={handleCloseNavMenu}>
              <Button color="inherit" smooth href={"/#solution"}>
                Solutions
              </Button>
            </MenuItem>
            <MenuItem onClick={handleCloseNavMenu}>
              <Button color="inherit" smooth href={"/#blog"}>
                Blogs
              </Button>
            </MenuItem>
            <MenuItem onClick={handleCloseNavMenu}>
              <Button color="inherit" smooth href={"/careers"}>
                Careers
              </Button>
            </MenuItem>
            <MenuItem onClick={handleCloseNavMenu}>
              <Button color="inherit" smooth href={"/#contact"}>
                Contact
              </Button>
            </MenuItem>
          </Menu>
        </Hidden>

        <Typography
          component="h6"
          variant="h6"
          color="inherit"
          sx={{ flexGrow: 1 }}
        >
          <img src={logo} alt="" />
        </Typography>
        <Hidden smDown>
          <Button color="inherit" href="/">
            Home
          </Button>
          <Button color="inherit" href="/#feature">
            What We Do
          </Button>
          <Button color="inherit" href="/#solution">
            Solutions
          </Button>
          <Button color="inherit" href="/#blog">
            Blogs
          </Button>
          <Button color="inherit" href="/careers">
            Careers
          </Button>
          <Button color="inherit" href="/#contact">
            Contact
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Navi;

export const AuthNavi = (props) => {
  const [open, setOpen] = useState(false);
  const { navType = "dark", title, link } = props;

  const toggle = () => setOpen(!open);
  return (
    <AppBar position="fixed" color={navType}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <img src={logo} alt="" style={{ filter: "saturate(3)" }} />
        </Typography>

        <IconButton color="inherit" onClick={toggle}>
          <MenuIcon />
        </IconButton>
        <Menu open={open} onClose={() => setOpen(false)}>
          <Button>
            <NavHashLink to={`/${link}`}>{title}</NavHashLink>
          </Button>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
