import { loadStripe } from "@stripe/stripe-js";
const BASE_CLOUD = "https://everlasting.work/api/v1/";
// const localDB = "http://localhost:5071/api/v1/";
const mykey = process.env.REACT_APP_auth;

export const auth = {
  headers: { Authorization: `Basic ${mykey}` },
};
export const PostSettings = (obj) => {
  return {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Basic ${process.env.REACT_APP_auth}`,
    },
    body: JSON.stringify(obj),
  };
};
export const PostSettings2 = (obj) => {
  return {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Basic ${process.env.REACT_APP_auth2}`,
    },
    body: JSON.stringify(obj),
  };
};
export const esslContact = async (obj) => {
  const response = await fetch(`${BASE_CLOUD}contact`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Basic ${process.env.REACT_APP_auth2}`,
    },
    body: JSON.stringify(obj),
  });
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response.json();
  return { data };
};
export const handlePay = async (obj) => {
  const stripeInit = loadStripe(process.env.REACT_APP_stripe_public);
  const str = await stripeInit;
  const resp = await fetch(`${BASE_CLOUD}pay/session`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Basic ${process.env.REACT_APP_auth2}`,
    },
    body: JSON.stringify(obj),
  });

  // const resp = await fetch(
  // 	'https://ohqt0uf4t4.execute-api.eu-west-1.a.amazonaws.com/dev/post',
  // 	{
  // 		method: 'POST',
  // 		headers: {
  // 			Accept: 'application/json',
  // 			'Content-Type': 'application/json',
  // 			Authorization: `Basic ${process.env.REACT_APP_stripexau}`,
  // 		},
  // 		body: JSON.stringify(obj),
  // 	}
  // );
  const session = await resp.json();
  const res = await str.redirectToCheckout({
    sessionId: session.id,
  });
  if (res.error) {
    console.error(res.error, " what happened to stripe? ");
  }
  return await res.JSON();
};
export const getDomain = async (val) => {
  const resp = await fetch(
    `https://xtz05mugj7.execute-api.eu-west-1.amazonaws.com/dev/essl/domain/${val}`,
    { headers: { Authorization: `Basic ${process.env.REACT_APP_auth}` } }
  );
  const res = await resp.json();
  if (res.error) {
    console.error(res.error, "Get domain failed ");
  }
  return res;
};

export const submitApplication = async (obj) => {
  const response = await fetch(
    `${BASE_CLOUD}career/add`,
    PostSettings2(obj)
  ).catch((error) => error);

  if (!response.ok || response.error) {
    return { error: { code: response.status } };
  }
  const data = await response.json();
  return { data };
};
export const addStaff = async (obj) => {
  const response = await fetch(
    `${BASE_CLOUD}career/staff`,
    PostSettings2(obj)
  ).catch((error) => error);

  if (!response.ok || response.error) {
    return { error: { code: response.status } };
  }
  const data = await response.json();
  return { data };
};
export const getAllApplication = async () => {
  const response = await fetch(`${BASE_CLOUD}career/all`, {
    headers: { Authorization: `Basic ${process.env.REACT_APP_auth2}` },
  }).catch((error) => error);

  if (!response.ok || response.error) {
    return { error: { code: response.status } };
  }

  const data = await response.json();
  return { data };
};

export const esslCourse = async (obj) => {
  const response = await fetch(
    `http://147.182.236.146:8080/campus/api/v1/contact/essl/new`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Basic ${process.env.REACT_APP_auth2}`,
      },
      body: JSON.stringify(obj),
    }
  );
  if (!response.ok) {
    return { error: { code: response.status } };
  }
  const data = await response;
  return { data };
};
