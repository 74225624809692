import React from "react";
import { Col, Container, Row } from "reactstrap";
import back from "../../assets/img/horace-landing.webp";
import front from "../../assets/img/reachai-landing.webp";
import { NavHashLink } from "react-router-hash-link";

const Intro = () => {
  return (
    <section
      id="intro"
      className="main-section parallax"
      data-parallax="scroll"
    >
      <Container>
        <Row>
          <Col>
            <div className="intro-text">
              <h1 className="wow fadeInUp" data-wow-delay=".2s">
                Revolutionize your business!
              </h1>

              <p className="font-alt wow fadeInUp" data-wow-delay=".4s">
                with our awesome SaaS Solutions
              </p>

              <div className="btns">
                <NavHashLink
                  smooth
                  to={"/#contact"}
                  className="app-btn wow bounceIn"
                  data-wow-delay=".6s"
                >
                  <i className="ion-social-usd"></i>
                  Request Demo
                </NavHashLink>

                <a
                  id="go-to-reach"
                  href="https://reachai.online/website-builder"
                  target="_blank"
                  rel="noreferrer"
                  className="app-btn wow bounceIn"
                  data-wow-delay=".8s"
                >
                  <i className="ion-code"></i>
                  Build A Website
                </a>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mockup">
              <img
                src={front}
                className="front wow fadeInDown"
                data-wow-duration=".7s"
                data-wow-delay="1.6s"
                alt="front"
                width="100%"
              />
              <img
                src={back}
                className="back wow fadeInDown"
                data-wow-duration=".7s"
                data-wow-delay="1.3s"
                alt="back"
                width="80%"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Intro;
