import {
  Card,
  CardContent,
  CardMedia,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import Navi from "../../component/Navi";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles(() => ({
  cardBox: {
    width: "50%",
    margin: "0 auto",
    marginTop: 30,
  },
  cardDetail: { display: "flex", flexDirection: "row" },
  cancel: { fontSize: 80, textAlign: "center" },
}));

const Igbo = () => {
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>ESSL - Transaction</title>
        <meta
          name="description"
          content="Welcome to Everlasting Systems and Solutions LLC (ESSL)"
        ></meta>
        <meta
          name="keywords"
          content="website development in Cypress, website development in Houston,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency, software development firm, software development company, software development company, software development company, software development company, software development company, software development company, software development company, software development company, ESSL"
        />
      </Helmet>
      <section id="contact" className="contact-section">
        <Navi navType="dark" />
        <Container>
          <div className="section-header text-center">
            <Typography
              variant="h2"
              className="wow fadeInUp"
              data-wow-delay=".2s"
              color="error"
            >
              Transaction Failed
            </Typography>
          </div>

          <Card className={classes.cardBox}>
            <div className={classes.cardDetail}>
              <CardMedia>
                <CancelIcon color="error" className={classes.cancel} />
              </CardMedia>
              <CardContent>
                <Typography variant="body1">
                  You canceled the payment or the payment processor couldn't
                  process the transaction
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Container>
      </section>
    </>
  );
};

export default Igbo;
